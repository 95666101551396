import React from "react";
import styled from "styled-components";
import { TransactionItem } from "../../contexts/Context";
import { formatCurrency } from "../../utils";

const Card = styled.div`
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  position: relative;
`;

const CardWrapper = styled.div`
  display: flex;
`;

const CardDetail = styled.div`
  margin-left: 15px;
  flex-grow: 1;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #04070d;
  }
  .desc {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #3c414d;
  }
`;

const No = styled.div`
  border-radius: 50%;
  background-color: #215cec;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 36px;
  font-size: 1.3rem;
  text-align: center;
  padding: 2px;
`;

interface Props {
  no: number;
  item: TransactionItem;
}

const ItemCard: React.FC<Props> = ({ no, item }) => {
  return (
    <Card>
      <CardWrapper>
        <No>{no}</No>
        <CardDetail>
          <div className="title">{item.name}</div>
          <div className="desc">
            {item.qty} X {formatCurrency(+item.price)} THB
          </div>
        </CardDetail>
      </CardWrapper>
    </Card>
  );
};

export default ItemCard;
