import React from "react";
import styled from "styled-components";

const StyledStatus = styled.div`
  margin-left: 15px;
  border: 2px solid #2ec02e;
  color: #2ec02e;
  padding: 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;

  &.cancelled {
    border-color: #f95c5c;
    color: #f95c5c;
  }
`;

interface Props {
  status: string;
}

const Status: React.FC<Props> = ({ status }) => {
  return <StyledStatus className={status}>{status}</StyledStatus>;
};

export default Status;
