import React from "react";
import styled from "styled-components";
import { parseISO, format } from "date-fns";

import Status from "../../components/Status";
import ItemCard from "./ItemCard";
import { Transaction } from "../../contexts/Context";
import { formatCurrency } from "../../utils";

const Card = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-top: 3px solid #215cec;

  .title-box {
    flex-grow: 1;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 700;
    color: #215cec;
  }
  .sub-title {
    margin-top: 5px;
    font-size: 1.1rem;
    color: #a1a1a1;
  }
`;

const CardDetail = styled.div`
  padding: 10px 15px 20px;
`;

const TotalPrice = styled.div`
  text-align: right;
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #3c414d;
`;

interface Props {
  transaction?: Transaction;
}

const TranasctionDetail: React.FC<Props> = ({ transaction }) => {
  if (transaction) {
    const createdAt = parseISO(transaction.created_at);
    const formatedCreatedAt = format(createdAt, "dd MMM yyyy HH:mm:ss");
    const items = transaction.items;

    return (
      <Card>
        <CardHeader>
          <div className="title-box">
            <div className="title">#{transaction.short_id}</div>
            <div className="sub-title">Date: {formatedCreatedAt}</div>
          </div>
          <Status status={transaction.status} />
        </CardHeader>
        <CardDetail>
          {items.map((item, index) => (
            <ItemCard no={index + 1} item={item} key={item.id} />
          ))}
          <TotalPrice>
            Total: {formatCurrency(+transaction.amount)} THB
          </TotalPrice>
        </CardDetail>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <div className="title">Transaction not found</div>
      </CardHeader>
    </Card>
  );
};

export default TranasctionDetail;
