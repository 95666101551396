import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

import Status from "../../components/Status";
import { Transaction } from "../../contexts/Context";
import { formatCurrency } from "../../utils";

const Card = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-top: 3px solid #215cec;

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    flex-grow: 1;
    color: #215cec;
  }
`;

const CardDetail = styled.div`
  padding: 10px 15px 20px;
`;

const CardDetailItem = styled.div`
  font-size: 1.3rem;
  color: #04070d;

  label {
    font-size: 1.3rem;
    font-weight: 700;
    margin-right: 10px;
  }

  & + div {
    margin-top: 10px;
  }
`;

const MoreDetail = styled.div`
  text-align: right;

  a {
    display: inline-block;
    margin-top: 20px;
    text-decoration: underline;
    font-size: 1.2rem;
    font-weight: 700;
    color: #3c414d;
  }
`;

interface Props {
  transaction: Transaction;
}

const TransactionCard: React.FC<Props> = ({ transaction }) => {
  const createdAt = parseISO(transaction.created_at);
  const formatedCreatedAt = format(createdAt, "dd MMM yyyy HH:mm:ss");

  return (
    <Card>
      <CardHeader>
        <div className="title">#{transaction.short_id}</div>
        <Status status={transaction.status} />
      </CardHeader>
      <CardDetail>
        <CardDetailItem>
          <label>Date:</label>
          {formatedCreatedAt}
        </CardDetailItem>
        <CardDetailItem>
          <label>Total:</label>
          {formatCurrency(+transaction.amount)} THB
        </CardDetailItem>
        <MoreDetail>
          <Link to={`/transaction/${transaction.short_id}`}>More Details</Link>
        </MoreDetail>
      </CardDetail>
    </Card>
  );
};

export default TransactionCard;
