import TransactionList from "../components/TransactionList/TransactionList";
import { Transaction, TransactionPaginate, useGlobalContext } from "../contexts/Context";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Pagination } from "@mui/material";

const MissingTrans = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
  color: #04070d;
  flex-grow: 1;
  padding-right: 15px;
  margin-top: 3rem;
`;

const Transactions: React.FC<any> = () => {
  const page_size = 10;
  const [loading, setLoading] = useState(true)
  const [transactions, setTransaction] = useState<Array<Transaction>>([])
  const [paginate, setPaginate] = useState<TransactionPaginate>({
    page_number: 1,
    page_size,
    total_pages: 1,
    total_entries: 1,
  })
  const { action } = useGlobalContext();

  useEffect(() => {
    fetchData(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async (page: number) => {
    setLoading(true)
    const result = await action.getTransactions(page, page_size);
    if (result) {
      setTransaction(result.data.data)
      setPaginate(result.data.paginate)
    }
    setLoading(false)
  }
  const handlePaginateChange = (event: React.ChangeEvent<unknown>, page: number) => {
    if (page === null) return;
    fetchData(page)
  }

  return (
    loading ? (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </>
    ) : (
        transactions?.length ? (
          <>
            <TransactionList
              transactions={transactions}
            />
            <Pagination count={paginate.total_pages}
              color="primary"
              page={paginate.page_number} onChange={handlePaginateChange} />
          </>
        ) : (
          <MissingTrans>No Transactions</MissingTrans>
          )
    )
  );
};

export default Transactions;
