import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box
  }
  html {
    font-size: 10px;
    &::-webkit-scrollbar{
      width: 0.5rem;
    }
    &::-webkit-scrollbar-thumb{
      background-color: darkgrey;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
    @media (max-width: 1700px){
      font-size: 75%;
      overflow-x: hidden;
    }
  }
  body {
    background: #fff;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
  }
  button {
    font-weight: 700;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 1rem 2rem;
    background: transparent;
    color: white;
    transition: all 0.5s ease;
    font-family: 'Inter', sans-serif;
  }

  #alert-dialog-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    font-weight: 700;
    color: #215cec;
  }

  #alert-dialog-description {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #3c414d;
  }
  .MuiDialogTitle-root {
    padding-bottom: 5px !important;
  }
  .MuiDialogContent-root {
    padding-bottom: 0 !important;
  }
  .dialog-danger {
    #alert-dialog-title {
      color: #f95c5c;
    }

    #alert-dialog-description {
      color: #3c414d;
    }
  }
`;

export default GlobalStyle;
