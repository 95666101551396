
export interface Product {
    id: number;
    name: string;
    price: number;
    img: string;
}

const imageURL = `${process.env.PUBLIC_URL}/assets/images`

export const productList: Array<Product> = [
    {
        id: 1,
        name: "GR Supra",
        price: 5199000,
        img: `${imageURL}/gr_supra_blue.png`,
    },
    {
        id: 2,
        name: "Camry",
        price: 1475000,
        img: `${imageURL}/camry.png`,
    },
    {
        id: 3,
        name: "Corolla Cross",
        price: 989000,
        img: `${imageURL}/corolla_cross.png`,
    },
    {
        id: 4,
        name: "C-HR",
        price: 1139000,
        img: `${imageURL}/c_hr.png`,
    },
    {
        id: 5,
        name: "GR Yaris",
        price: 2690000,
        img: `${imageURL}/yaris.png`,
    },
    {
        id: 6,
        name: "Sineta",
        price: 20,
        img: `${imageURL}/sienta.png`,
    },
    {
        id: 7,
        name: "Vios",
        price: 400000,
        img: `${imageURL}/vios.png`,
    },
    {
        id: 8,
        name: "Altis GR",
        price: 600000,
        img: `${imageURL}/altis_gr.png`,
    },
    {
        id: 9,
        name: "Fortuner",
        price: 0.01,
        img: `${imageURL}/fortuner.png`,
    }
]