import React from "react";
import styled from "styled-components";

import { formatCurrency } from "../../utils";
import { useGlobalContext, Product } from "../../contexts/Context";

const Card = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 33.3333333333%;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #fff;
  padding: 15px 15px 0;
`;

const Image = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const CardDetail = styled.div`
  background-color: #fff;
  padding: 15px 15px 30px;
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  flex-grow: 1;
  padding-right: 15px;
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #04070d;
  }
  .desc {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #3c414d;
  }
`;

const AddToCartBtn = styled.button`
  color: #215cec;
  font-size: 1.2rem;
  border: 2px solid #215cec;
  border-radius: 20px;
  padding: 10px 15px;
  /* width: 100%; */

  &:hover {
    background-color: #215cec;
    color: #fff;
  }
`;

interface Props {
  product: Product;
}

const ProductCard: React.FC<Props> = ({ product }) => {
  const { action } = useGlobalContext();

  const addToCart = () => {
    action.addCart(product);
  };

  return (
    <Card>
      <CardWrapper>
        <Image style={{ backgroundImage: `url(${product.img})` }} />
        <CardDetail>
          <Description>
            <div className="title">{product.name}</div>
            <div className="desc">{formatCurrency(product.price)} THB</div>
          </Description>
          <AddToCartBtn onClick={addToCart}>Add to cart</AddToCartBtn>
        </CardDetail>
      </CardWrapper>
    </Card>
  );
};

export default ProductCard;
