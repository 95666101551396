import { createContext, useContext, useState, useEffect } from "react";
import SDK from "gcp-miniappkit-jssdk";
import axios from "axios";
import { productList } from "./ProductMock";

export interface CreatedTransaction {
  tx_id: string;
  amount: string;
  currency: string;
  description: string;
  merchant_name: string;
  ref_1: string;
  short_id: string;
  status: string;
  created_at: string;
  deep_link: string;
  qr: string;
}
export interface Transaction {
  tx_id: string;
  amount: string;
  currency: string;
  description: string;
  merchant_name: string;
  ref_1: string;
  short_id: string;
  status: string;
  created_at: string;
  items: Array<TransactionItem>;
}

export interface TransactionPaginate {
  page_number: number;
  page_size: number;
  total_pages: number;
  total_entries: number;
}

export interface TransactionItem {
  id: number;
  name: string;
  price: string;
  currency: string;
  qty: string;
  total_price: string;
}

export interface Product {
  id: number;
  name: string;
  price: number;
  img: string;
}
export interface CartItem extends Product {
  qty: number;
}

type GlobalContent = {
  openSuccess: boolean;
  openFail: boolean;
  action: any;
  paymentStatus: string;
  errorMessage: string;
  openEmpty: boolean;
  products: Array<Product>;
  cart: Array<CartItem>;
};

const GlobalContext = createContext<GlobalContent>({
  openSuccess: false,
  openFail: false,
  action: {},
  paymentStatus: "",
  errorMessage: "",
  openEmpty: false,
  products: [],
  cart: [],
});

export const useGlobalContext = () => useContext(GlobalContext);
interface Props {
  children: React.ReactNode;
}

export const ContextProvider: React.FC<Props> = ({ children }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [openEmpty, setOpenEmpty] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cart, setCart] = useState<Array<CartItem>>([]);
  const [products, setProducts] = useState<Array<Product>>(productList);

  useEffect(() => {
    SDK.init(process.env.REACT_APP_MINIAPP_BUNDLE_ID);
  }, []);

  const getToken = async () => {
    if (process.env.REACT_APP_ENV === "staging" && process.env.REACT_APP_MINIAPP_TOKEN) {
      return process.env.REACT_APP_MINIAPP_TOKEN;
    }

    const { token } = await SDK.getAccessToken();

    return token;
  };

  const getTransactions = async (page: number = 1, page_size: number = 10) => {
    const token = await getToken();

    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/transactions`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          page_size,
        }
      })
  };

  const getTransactionByTxId = async (tx_id: string) => {
    const token = await getToken();

    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/transaction/${tx_id}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
  }

  const createTransaction = async (
    cart: Array<CartItem>
  ): Promise<CreatedTransaction> => {
    return new Promise(async (resolve, reject) => {
      const token = await getToken();

      axios
        .post<any>(
          `${process.env.REACT_APP_API_URL}/api/transaction`,
          {
            products: cart.map((cartItem: CartItem) => ({
              name: cartItem.name,
              price: (cartItem.price * 100).toString(),
              qty: cartItem.qty,
            })),
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const cancelTransaction = async (
    txId: string
  ): Promise<CreatedTransaction> => {
    return new Promise(async (resolve, reject) => {
      const token = await getToken();

      axios
        .post<any>(
          `${process.env.REACT_APP_API_URL}/api/transaction.cancel`,
          {
            tx_id: txId,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const makePayment = async (cart: Array<CartItem>) => {
    try {
      const transaction = await createTransaction(cart);
      const data = await SDK.processPayment({
        deepLink: transaction.deep_link,
        qr: transaction.qr,
      });

      const status = data.status

      if (status === "success") { 
        setSuccessPaymentStatus();
        return;
      }

      await cancelTransaction(transaction.tx_id);

      if (status === "canceled") {
        setCanceledPaymentStatus();
        return;
      }

      if (status === "error") {
        setFaiedPaymentStatus();
        return
      }

    } catch {
      setErrorMessage("Failed to process payment");
      setPaymentStatus("failed");
    }
  };

  const addCart = (product: Product) => {
    let foundProduct = false;
    const newCart = cart.map((item) => {
      if (item.id === product.id) {
        foundProduct = true;

        return { ...item, qty: item.qty + 1 };
      }

      return item;
    });

    if (!foundProduct) {
      newCart.push({ ...product, qty: 1 });
    }

    setCart(newCart);
  };

  const removeCartById = (id: number) => {
    const newCart: Array<CartItem> = [];

    cart.forEach((item) => {
      if (item.id === id && item.qty > 1) {
        newCart.push({ ...item, qty: item.qty - 1 });
      } else if (item.id !== id) {
        newCart.push({ ...item });
      }
    });

    setCart(newCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  const createFailedCheckout = async (cart: Array<CartItem>) => {
    if (SDK.platform === "web") {
      return {
        "isErrorMock": true,
      };
    }
    const deepLink = "demo-mini-app://for.testing.purpose/pay?merchant_identifier=1234567890&merchant_reference=1234567890"
    try {
      const transaction = await createTransaction(cart);
      const data = await SDK.processPayment({
        deepLink,
        qr: "mock"
      });
      const status = data.status

      await cancelTransaction(transaction.tx_id);

      if (status === "canceled") {
        setCanceledPaymentStatus();
        return;
      }

      setFaiedPaymentStatus();

    } catch {
      setErrorMessage("Failed to process payment");
      setPaymentStatus("failed");
    }
  }

  const setSuccessPaymentStatus = () => {
    setPaymentStatus("success")
    setErrorMessage("");
    return;
  }

  const setCanceledPaymentStatus = () => {
    setPaymentStatus("canceled");
    setErrorMessage("");
    return;
  }

  const setFaiedPaymentStatus = () => {
    setErrorMessage("Failed to process payment");
    setPaymentStatus("failed");
    return;
  };


  const action = {
    setOpenSuccess,
    setOpenFail,
    setOpenEmpty,
    getToken,
    makePayment,
    getTransactions,
    getTransactionByTxId,
    setPaymentStatus,
    setProducts,
    addCart,
    removeCartById,
    clearCart,
    createFailedCheckout,
  };

  return (
    <GlobalContext.Provider
      value={{
        openSuccess,
        action,
        paymentStatus,
        errorMessage,
        openFail,
        openEmpty,
        products,
        cart,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
