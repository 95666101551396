import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TransactionDetail from "../components/TransactionDetail/TransactionDetail";
import { Transaction, useGlobalContext } from "../contexts/Context";

interface Params {
  id: string;
}

const Transactions: React.FC<any> = () => {
  const { id }: Params = useParams()
  const { action } = useGlobalContext();
  const [loading, setLoading] = useState(false)
  const [transaction, setTransaction] = useState<Transaction>()

  useEffect(() => {
    fetchData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async (id: string) => {
    setLoading(true)
    const result = await action.getTransactionByTxId(id);
    if (result) {
      setTransaction(result.data)
    }
    setLoading(false)
  }

  return (
    loading ? (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </>
    ) : (
        <>
          <TransactionDetail transaction={transaction} />
        </>
      )
  );
};

export default Transactions;
