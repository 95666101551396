import React from "react";
import styled from "styled-components";
import ItemCard from "./ItemCard";
import { useGlobalContext } from "../../contexts/Context";

const CartListContainer = styled.div``;

const CartList: React.FC<any> = () => {
  const { cart } = useGlobalContext();
  return (
    <CartListContainer>
      {cart.map((item) => (
        <ItemCard key={item.id} item={item} />
      ))}
    </CartListContainer>
  );
};

export default CartList;
