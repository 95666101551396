import React from "react";
import styled from "styled-components";

import { formatCurrency } from "../../utils";
import { useGlobalContext, CartItem } from "../../contexts/Context";

const Card = styled.div`
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CardWrapper = styled.div`
  display: flex;
`;

const Image = styled.img`
  width: 100px;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 150px;
  }
`;

const CardDetail = styled.div`
  margin-left: 15px;
  flex-grow: 1;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #04070d;
  }
  .desc {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #3c414d;
  }
`;

const DeleteBtn = styled.button`
  border: none;
  background-color: #ccc;
  border-radius: 50%;
  padding: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  position: absolute;
  top: -10px;
  right: -10px;

  &:hover {
    background-color: #fb6767;
  }
`;

interface Props {
  item: CartItem;
}

const ItemCard: React.FC<Props> = ({ item }) => {
  const { action } = useGlobalContext();

  const removeFromCart = () => {
    action.removeCartById(item.id);
  };

  return (
    <Card>
      <CardWrapper>
        <Image src={item.img} />
        <CardDetail>
          <div className="title">{item.name}</div>
          <div className="desc">
            {item.qty} X {formatCurrency(item.price)} THB
          </div>
        </CardDetail>
      </CardWrapper>
      <DeleteBtn onClick={removeFromCart}>X</DeleteBtn>
    </Card>
  );
};

export default ItemCard;
