import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useGlobalContext } from "../../contexts/Context";
import { formatCurrency } from "../../utils";

import styled from "styled-components";

const CheckoutBtn = styled.button`
  color: #fff;
  font-size: 1.5rem;
  border: none;
  background-color: #215cec;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
  margin-top: 15px;

  &:hover {
    background-color: #1f57e0;
    color: #fff;
  }
  :disabled {
    opacity: 0.5;
    pointer-events: null;
  }
`;

const FailedCheckoutBth = styled(CheckoutBtn)`
  background-color: #f95c5c;

  &:hover {
    background-color: #f95c5c;
    color: #fff;
  }
  :disabled {
    opacity: 0.5;
    pointer-events: null;
  }
`

const Image = styled.img`
  display: block;
  width: 67px;
  margin: 16px auto;
`;

const ActionContainer = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
`;

const CloseBtn = styled.button`
  color: #fff;
  font-size: 1.5rem;
  border: none;
  background-color: #215cec;
  border-radius: 5px;
  padding: 10px 50px;

  &:hover {
    background-color: #1f57e0;
    color: #fff;
  }

  &.danger {
    background-color: #f95c5c;

    &:hover {
      background-color: #f95c5c;
    }
  }
`;

const Checkout: React.FC<any> = () => {
  const history = useHistory();
  const [disabledCheckoutBtn, setDisabledCheckoutBtn] = useState(false);
  const { cart, openSuccess, openFail, openEmpty, paymentStatus, errorMessage, action } =
    useGlobalContext();

  const isShowFailedCheckout = (process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "UAT");

  const price = cart.reduce((prevPrice, item) => {
    return prevPrice + item.price * item.qty;
  }, 0);

  const handleClickOpen = async () => {
    if (price > 0) {
      setDisabledCheckoutBtn(true);
      await action.makePayment(cart);
      setDisabledCheckoutBtn(false);
    } else {
      action.setOpenEmpty(true);
    }
  };

  const handleFailedCheckout = async () => {
    if (price > 0) {
      setDisabledCheckoutBtn(true);
      const data = await action.createFailedCheckout(cart);
      if (data?.isErrorMock) {
        action.setOpenFail(true);
      }
      setDisabledCheckoutBtn(false);
    } else {
      action.setOpenEmpty(true);
    }
  }

  const handleCloseSuccess = () => {
    action.setOpenSuccess(false);
    action.setPaymentStatus("");
    history.push("/");
  };

  const handleCloseFail = () => {
    action.setOpenFail(false);
    action.setPaymentStatus("");
    setDisabledCheckoutBtn(false);
  };

  const handleCloseEmpty = () => {
    action.setOpenEmpty(false);
    action.setPaymentStatus("");
  };

  useEffect(() => {
    if (paymentStatus === "success") {
      action.setOpenSuccess(true);
      action.clearCart();
    } else if (paymentStatus === "failed") {
      action.setOpenFail(true);
    } else if (paymentStatus === "canceled") {
      action.setOpenEmpty(false);
      action.setPaymentStatus("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  return (
    <>
      <CheckoutBtn
        onClick={handleClickOpen}
        disabled={disabledCheckoutBtn}
      >
        Checkout: {formatCurrency(price)} THB
      </CheckoutBtn>
      {
        isShowFailedCheckout &&
        <FailedCheckoutBth
          onClick={handleFailedCheckout}
          disabled={disabledCheckoutBtn}
        >
          Checkout: {formatCurrency(price)} THB
        </FailedCheckoutBth>
      }

      <Dialog
        open={openSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Transaction succeeded
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The purchase was done!
          </DialogContentText>
          <Image src="icons/success.svg" />
        </DialogContent>
        <DialogActions>
          <ActionContainer>
            <CloseBtn onClick={handleCloseSuccess} autoFocus>
              OK
            </CloseBtn>
          </ActionContainer>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFail}
        onClose={handleCloseFail}
        className="dialog-danger"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Transaction failed 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
          <Image src="icons/failed.svg" />
        </DialogContent>
        <DialogActions>
          <ActionContainer>
            <CloseBtn className="danger" onClick={handleCloseFail} autoFocus>
              OK
            </CloseBtn>
          </ActionContainer>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEmpty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{"Cart Empty"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add Items to Cart
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ActionContainer>
            <CloseBtn onClick={handleCloseEmpty} autoFocus>
              Close
            </CloseBtn>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Checkout;
