import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const StyledMainContainer = styled.div`
  margin-top: 60px;
  padding: 15px;
`;

const MainContainer: React.FC<Props> = ({ children }) => {
  return <StyledMainContainer>{children}</StyledMainContainer>;
};

export default MainContainer;
