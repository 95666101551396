import CartList from "../components/CartList/CartList";
import React from "react";

import Checkout from "../components/Checkout/Checkout";

const Cart: React.FC<any> = () => {
  return (
    <>
      <CartList />
      <Checkout />
    </>
  );
};

export default Cart;
