import React from "react";
import styled from "styled-components";
import TransactionCard from "./TransactionCard";
import { Transaction } from "../../contexts/Context";

const TransactionListContainer = styled.div``;

interface Props {
  transactions: Array<Transaction>;
}

const TransactionList: React.FC<Props> = ({ transactions }) => {

  return (
    <TransactionListContainer>
      {transactions.map((transaction) => (
        <TransactionCard key={Math.random()} transaction={transaction} />
      ))}
    </TransactionListContainer>
  );
};

export default TransactionList;
