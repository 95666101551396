import React from "react";
import styled from "styled-components";
import ProductCard from "./ProductCard";
import { useGlobalContext } from "../../contexts/Context";

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const ProductList: React.FC<any> = () => {
  const { products } = useGlobalContext();
  return (
    <ProductListContainer>
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </ProductListContainer>
  );
};

export default ProductList;
