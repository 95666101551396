import GlobalStyle from "./components/GlobalStyle";

import { Switch, Route, Redirect } from "react-router-dom";

import Header from "./layout/Header";
import MainContainer from "./layout/MainContainer";

import MainPage from "./pages/Main";
import CartPage from "./pages/Cart";
import TransactionsPage from "./pages/Transactions";
import TransactionPage from "./pages/Transaction";

import { ContextProvider } from "./contexts/Context";

function App() {
  return (
    <ContextProvider>
      <GlobalStyle />

      <Header />
      <MainContainer>
        <Switch>
          <Route exact path="/cart">
            <CartPage />
          </Route>
          <Route exact path="/transactions">
            <TransactionsPage />
          </Route>
          <Route exact path="/transaction/:id">
            <TransactionPage />
          </Route>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </MainContainer>
    </ContextProvider>
  );
}

export default App;
