import React from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { VscArrowLeft } from "react-icons/vsc";
import { AiOutlineShoppingCart, AiOutlineUnorderedList } from "react-icons/ai";

import { useGlobalContext } from "../contexts/Context";

const StyledHeader = styled.header`
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;

  button,
  a {
    display: inline-block;
    text-decoration: none;
    color: #215cec;
    font-size: 1.8rem;
    border: none;
    margin: 0;
    padding: 0;
    transition: none;

    :disabled {
      opacity: 0;
      cursor: default;
      pointer-events: none;
    }
  }
  .title {
    font-size: 1.6rem;
    font-weight: 700;
    color: #215cec;
    margin-left: 2rem;
  }
  .cart,
  .list {
    border-radius: 50%;
    background-color: #215cec;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    position: relative;
    margin-right: 1rem;
  }
`;

const Pages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemCounter = styled.span`
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  color: #0037a6;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 0.7rem;
  text-align: center;
  padding: 2px;
  position: absolute;
  top: -6px;
  right: -4px;
`;

const Header: React.FC<any> = () => {
  const history = useHistory();
  const location = useLocation();
  const { cart } = useGlobalContext();

  const countCart = cart.reduce((prevCount, item) => {
    return prevCount + item.qty;
  }, 0);

  const disabledBackBtn = location.pathname === "/";

  const goBack = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!disabledBackBtn) {
      history.goBack();
    }
  };

  return (
    <StyledHeader>
      <button onClick={goBack} disabled={disabledBackBtn}>
        <VscArrowLeft />
      </button>
      <Link className="title" to="/">
        DEMO
      </Link>
      <Pages>
        <Link className="cart" to="/cart">
          <AiOutlineShoppingCart />
          {countCart > 0 && <ItemCounter>{countCart}</ItemCounter>}
        </Link>
        <Link className="list" to="/transactions">
          <AiOutlineUnorderedList />
        </Link>
      </Pages>
    </StyledHeader>
  );
};

export default Header;
